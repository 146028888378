import React from 'react';
import GatsbyImage, { GatsbyImageFluidProps } from 'gatsby-image';
import ColorOverlayFilter from '../utils/ColorOverlayFilter';
import { SanityImageFluidProps } from '../types/types';
import styled from 'styled-components';
import theme from './theme';

interface Props {
  featured: SanityImageFluidProps[];
  logoColor: string;
  opacity?: number;
  filterId: string;
}

const Featured: React.FC<Props> = ({
  featured,
  logoColor,
  opacity,
  filterId,
}) => (
  <>
    <FeatureWrap>
      <ColorOverlayFilter color={logoColor} id={filterId} opacity={opacity} />
      <FeaturedLogos>
        {featured.map((featuredLogo) => (
          <Logo
            key={featuredLogo._key}
            fluid={featuredLogo.asset.fluid}
            className="featured-logo"
          />
        ))}
      </FeaturedLogos>
    </FeatureWrap>
  </>
);

export default Featured;

const FeatureWrap = styled.div`
  background: #6680ae;
  svg {
    display: none;
  }
`;

const FeaturedLogos = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 2rem;
  margin: 0 auto;
  margin-top: 4rem;
  max-width: 960px;
`;

const Logo = styled(GatsbyImage)<GatsbyImageFluidProps>`
  filter: url('#white');
  width: 50px;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    width: 75px;
  }
  @media screen and (min-width: ${theme.breakpoints.md}) {
    width: 125px;
  }
`;
