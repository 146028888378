import React from 'react';
import { BrandProps } from '../types/types';
import styled from 'styled-components';
import theme from './theme';

interface Props {
  brand: BrandProps;
}

const Footer: React.FC<Props> = ({ brand }) => (
  <StyledFooter>
    <div className="footer-inner">
      {brand.footerOveride ? (
        <>{brand.footerOveride}</>
      ) : (
        <>
          {`
To the extent that this communication is considered ATTORNEY
ADVERTISING, the Arkansas law firm of Linville Johnson, PLLC, through
${brand.legalName}. (hereinafter collectively referred to as "
${brand.name}") is responsible for the content of this communication. This
communication is designed for general informational purposes only and
it does not constitute the formation of a lawyer-client relationship.
${brand.name} aims to provide useful information in this communication,
but this should not be considered legal advice. While we endeavor to
provide information that is accurate and complete, ${brand.name} does
not warrant that the information is complete or accurate. ${brand.name}
disclaims all liability to any person for any loss caused by errors or
omissions in this collection of information. The hiring of a lawyer is
an important decision that should not be based solely upon
advertisements. Before you decide, ask us to send you free written
information about our qualifications and experience. NO REPRESENTATION
IS MADE REGARDING THE POTENTIAL OUTCOME OF ANY SPECIFIC CASE OR THAT
AT THE QUALITY OF ANY LEGAL SERVICES IS GREATER THAN THE QUALITY OF
LEGAL SERVICES PERFORMED BY OTHER ATTORNEYS.
`}
          <a
            className="footer-link"
            href={brand.tos}
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use and Privacy Policy.
          </a>
        </>
      )}
    </div>
  </StyledFooter>
);

export default Footer;

const StyledFooter = styled.footer`
  background: ${theme.colors.primary};
  color: white;

  .footer-inner {
    font-size: 0.75rem;
    line-height: 0.85rem;
    text-align: center;
    max-width: 960px;
    margin: 0 auto;
    padding: 4rem 4rem;
  }

  .footer-link {
    color: white;
  }
`;
